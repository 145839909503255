import React, { useContext, useEffect, useRef, useState } from "react";
import LeftHome from "./slices/LeftHome";
import MiddleHome from "./slices/MiddleHome";
import RightHome from "./slices/RightHome";
import TopMenuHeader from "./slices/TopMenuHeader";
import { UserContext } from "../UserContext";
import Search from "./slices/Search";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import { AnimatePresence, motion } from "framer-motion";
import FeedbackModal from "./slices/FeedbackModal";
import { useLayoutEffect } from "react";
import { Tooltip } from "react-tippy";
import FooterLogo from "../../../src/img/logo-white-blue.webp";

export default function Home() {
  const {
    isSmMenuOpen,
    setIsSmMenuOpen,
    isSearchOpen,
    setIsSearchOpen,
    darkTheme,
    bodyData,
    setBodyData,
    selectedHighlight,
    setSelectedHighlight,
    setResToken,
    setAllPosts,
    allPosts,
    setCodeNavigatorToken,
    setOpenMenu,
    openMenu,
    breadCrumb,
    setBreadCrumb,
    breadCrumbCategory,
    setBreadCrumbCategory,
    urlTagIdStore,
    setUrlTagIdStore,
    initialLeftScrollTrue,
    setInitialLeftScrollTrue,
    leftScrollFlag,
    setLeftScrollFlag,
    setPostId,
    isOpenLink,
    tokenMode,
  } = useContext(UserContext);

  // ACCESS TOKEN
  const accessToken = localStorage.getItem("access_token");

  // CN ACCESS TOKEN
  const cnAccessToken = localStorage.getItem("cn_access_token");

  // const [openMenu, setOpenMenu] = useState(null);

  const [subOpenMenu, setSubOpenMenu] = useState(null);
  const [searchInp, setSearchInp] = useState("");
  // const [bodyData, setBodyData] = useState({});
  const [pageHeadings, setPageHeadings] = useState([]);
  // const [selectedHighlight, setSelectedHighlight] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedHighlight) {
      if (isOpenLink) {
        navigate(`/${isOpenLink}/${selectedHighlight}`, { replace: true });
      } else {
        if (cnAccessToken || accessToken) {
          navigate(`/${selectedHighlight}`, { replace: true });
        } else {
          localStorage.removeItem("access_token");
          localStorage.removeItem("cn_access_token");
          setAllPosts([]);
          setResToken("");
          setCodeNavigatorToken("");
          toast.error("Session has been expired !");
          navigate("/");
        }
      }
    }
  }, [selectedHighlight, bodyData]);

  const toggleDropdown = (index, getData) => {
    // setElementsContent([]);

    // setOpenMenu(openMenu === index ? null : index);
    setBodyData(openMenu === index ? {} : getData);
    setIsSmMenuOpen(getData?.items?.length > 0 ? true : false);
    setSelectedHighlight(null);
  };

  const subToggleDropdown = (title, getData, index, categoryName) => {
    // if (getData?.slug !== selectedHighlight) {
    // setElementsContent([]);
    setSelectedHighlight(null);
    setBreadCrumb(getData);

    setSelectedHighlight(getData.slug);
    setBreadCrumbCategory(categoryName);
    setBreadCrumb(getData.title);
    setPostId(getData.id);

    setOpenMenu(openMenu === getData.id ? null : getData.id);
    setBodyData(getData);
    setPageHeadings(getData);
    setIsSmMenuOpen(false);
    setIsSearchOpen(false);
    setSearchInp("");

    // PreviousBtn Ref setUp

    // } else {
    //   setIsSmMenuOpen(false);

    //   setIsSearchOpen(false);
    // }
  };

  const SearchToggle = () => {
    setIsSearchOpen(false);
    setSearchInp("");
  };

  // Middle page scroll to top
  // const scrollToTop = () => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTo({ top: 0, behavior: 'smooth' }); // Set scroll position to the top
  //   }
  // };

  // RIGHT PAGE SET-UP ========KPK
  const containerRefWrapper = useRef(null);
  const containerRef = useRef(null);
  const sidebarRef = useRef(null);
  const [headings, setHeadings] = useState([]);
  const [activeHeadingId, setActiveHeadingId] = useState(null);
  const [expandedHeadings, setExpandedHeadings] = useState({});

  // TOP SCROLL WHEN bodyData UPDATED
  const addStringToUrl = () => {
    // Construct the new URL with the existing path and the appended string
    const newUrl = `${window.location.origin}${window.location.pathname}#${urlTagIdStore}`;

    // Update the URL without reloading the page
    // window.history.pushState({ path: newUrl }, '', newUrl);
    window.history.replaceState({ path: newUrl }, "", newUrl);
    // navigate(`${newUrl}`, { replace: false });
    // console.log("newwwUrl", window.history.replaceState({ path: newUrl }, '', newUrl))
  };
  useEffect(() => {
    if (urlTagIdStore === "") {
      if (containerRefWrapper.current) {
        containerRefWrapper.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      // URL ID SCROLL FUNCTIONALITY
      const contentEl = containerRef.current;
      if (urlTagIdStore) {
        const idUrlEl = contentEl.querySelector(`#${urlTagIdStore}`) || "";
        // console.log("idurl", idUrlEl)
        if (idUrlEl) {
          idUrlEl.scrollIntoView({ behavior: "smooth" });
          // WITHOUR RELOADING PAGE URL REPLACE
          // addStringToUrl();

          setUrlTagIdStore("");
        }
      }
    }
  }, [bodyData]);
  // Extract headings and build a hierarchical structure
  useEffect(() => {
    const contentEl = containerRef.current;

    if (contentEl) {
      const allHeadings = Array.from(
        contentEl.querySelectorAll("h1, h2, h3, h4")
      );

      // Build the hierarchy and set it in the state
      const structuredHeadings = buildHierarchy(allHeadings);
      setHeadings(structuredHeadings);

      // Observer to track when elements come into view
      // const observer = new IntersectionObserver(
      //   (entries) => {
      //     entries.forEach((entry) => {
      //       if (entry.isIntersecting) {
      //         setActiveHeadingId(entry.target.id);
      //       }
      //     });
      //   },
      //   { rootMargin: "0px 0px -60% 0px", threshold: 0.1 } // Adjust rootMargin to trigger midway
      // );

      // Scroll event handler to detect current heading based on scroll position
      const handleScroll = () => {
        let currentActiveId = null;

        allHeadings.forEach((heading) => {
          const rect = heading.getBoundingClientRect();
          // console.log(`Heading ${heading.tagName} ${heading.id} rect:`, rect);

          // observer.observe(heading);

          // Adjust the threshold based on your UI and the layout
          if (rect.top <= 100 && rect.bottom > 100) {
            currentActiveId = heading.id;
          }
        });

        if (currentActiveId !== activeHeadingId) {
          // console.log("Updating active heading state to:", currentActiveId);
          setActiveHeadingId(currentActiveId);
        }
      };

      // Attach the scroll listener to the content element
      contentEl.addEventListener("scroll", handleScroll);

      // Clean up the event listener on component unmount or updates
      return () => {
        contentEl.removeEventListener("scroll", handleScroll);
      };
    }
  }, [bodyData, activeHeadingId]);

  // GEATHER A TAG FROM POST
  useEffect(() => {
    containerRef.current.querySelectorAll("a").forEach((link) => {
      const remove1stImg = link.querySelector("img");
      if (remove1stImg) {
        link.removeChild(remove1stImg);
      }
      const ext = link.getAttribute("href").split(".").pop();
      let path = "./icons/";
      let icon = "";
      // link.setAttribute("target", "_blank");

      // for page scrolling
      const getHrefAtrr = link.getAttribute("href");
      const idLink = getHrefAtrr?.startsWith("#");
      if (!idLink) {
        link.setAttribute("target", "_blank");
        link.removeAttribute("download");
      } else {
        link.removeAttribute("target");
      }

      // itself Link: same tab open
      // const domainToFind = 'askcn.cloudframe.com';
      // const isDomainIncluded = getHrefAtrr?.includes(domainToFind);
      const domainRegex = /https?:\/\/(www\.)?askcn\.cloudframe\.com\b/;
      const isDomainIncluded = domainRegex.test(getHrefAtrr);
      if (!isDomainIncluded) {
        link.setAttribute("target", "_blank");
        link.removeAttribute("download");
      } else {
        link.removeAttribute("target");
      }

      link.style.cssText = `text-decoration: none;`;
      link.style.color = darkTheme ? "#11d9f0" : "#00B3F0";

      // if (ext === "pdf" || "zip" || "xlsx" || "csv" || "java") {
      //   link.removeAttribute("target");
      //   link.setAttribute("download", "");
      //   // link.style.cssText = `color: #00B3F0; text-decoration: none;`;
      // }else{
      //   link.setAttribute("target", "_blank");
      // }
      // {
      // link.setAttribute("target", "_blank");
      // link.addEventListener('mouseover', () => {
      //   link.style.color = '#3182ce';
      //   link.style.textDecoration = 'underline';
      // });
      // link.addEventListener('mouseout', () => {
      //   link.style.color = '#00B3F0';
      //   link.style.textDecoration = 'none';
      // });
      // }
      // console.log("exttt", ext)
      // switch (ext) {
      //   case "pdf":
      //     icon = "pdf-icon.png"; // Add path to your icon images
      //     break;
      //   case "zip":
      //     icon = "zip-icon.png";
      //     break;
      //   case "xlsx":
      //     icon = "xlsx-icon.png";
      //     break;
      //   case "csv":
      //     icon = "csv-icon.png";
      //     break;
      //   case "txt":
      //     icon = "txt-icon.png";
      //     break;
      //   case "java":
      //     icon = "java-icon.png";
      //     break;
      //   default:
      //     icon = "default-icon.png";
      //     break;
      // }
      // let img = document.createElement("img");
      // img.src = path + icon;
      // img.classList.add("icon");
      // link.prepend(img);
      // link.setAttribute("download", "");
      if (ext === "pdf") {
        icon = "pdf-icon.png";
        // link.removeAttribute("target");
        link.setAttribute("download", "");
        let img1 = document.createElement("img");
        img1.src = path + icon;
        img1.classList.add("icon");
        link.prepend(img1);
      } else {
        // const href=link.getAttribute("href");
        // const idLink= link.getAttribute("href")?.startsWith("#")
        // // const hostname = new URL(href).hostname;
        // // console.log("hrff",idLink)
        // if(!idLink){
        //   link.setAttribute("target", "_blank");
        //   link.removeAttribute("download");
        // }
        //   link.setAttribute("target", "_blank");
        //   link.removeAttribute("download");
      }
      if (ext === "zip") {
        icon = "zip-icon.png";
        link.removeAttribute("target");
        link.setAttribute("download", "");
        let img2 = document.createElement("img");
        img2.src = path + icon;
        img2.classList.add("icon");
        link.prepend(img2);
      }
      if (ext === "xlsx") {
        icon = "xlsx-icon.png";
        link.removeAttribute("target");
        link.setAttribute("download", "");
        let img3 = document.createElement("img");
        img3.src = path + icon;
        img3.classList.add("icon");
        link.prepend(img3);
      }
      if (ext === "csv") {
        icon = "csv-icon.png";
        link.removeAttribute("target");
        link.setAttribute("download", "");
        let img4 = document.createElement("img");
        icon = "csv-icon.png";
        img4.src = path + icon;
        img4.classList.add("icon");
        link.prepend(img4);
      }
      if (ext === "java") {
        icon = "java-icon.png";
        link.removeAttribute("target");
        link.setAttribute("download", "");
        let img5 = document.createElement("img");
        img5.src = path + icon;
        img5.classList.add("icon");
        link.prepend(img5);
      }
      if (ext === "txt") {
        icon = "txt-icon.png";
        link.removeAttribute("target");
        link.setAttribute("download", "");
        let img6 = document.createElement("img");
        img6.src = path + icon;
        img6.classList.add("icon");
        link.prepend(img6);
      }
    });
    // }, [bodyData, darkTheme]);
  }, [bodyData]);

  // Set up IntersectionObserver to update activeHeadingId
  //  useEffect(() => {
  //   if (!containerRef.current) return;

  //   const headings = Array.from(containerRef.current.querySelectorAll('h1, h2, h3, h4'));
  //   console.log('Observed Headings:', headings.map(h => h.id)); // Check for IDs

  //   const observerOptions = {
  //     root: containerRef.current, // The scrollable container
  //     rootMargin: '0px 0px -70% 0px', // Adjust to mark items as active earlier or later
  //     threshold: 0.1 // When 10% of an element is visible, consider it intersecting
  //   };

  //   const observerCallback = (entries) => {
  //     // Find the first intersecting entry
  //     const visibleEntry = entries.find(entry => entry.isIntersecting);

  //     // Update active heading ID if a visible heading is found
  //     if (visibleEntry) {
  //       console.log('Visible heading:', visibleEntry.target.id); // Debugging line
  //       setActiveHeadingId(visibleEntry.target.id);
  //     }
  //   };

  //   // Create a new IntersectionObserver with the above options
  //   const observer = new IntersectionObserver(observerCallback, observerOptions);

  //   // Observe each heading
  //   headings.forEach((heading) => {
  //     if (heading.id) {
  //       observer.observe(heading);
  //     }
  //   });

  //   // Cleanup observer when the component is unmounted or rawHTML changes
  //   return () => {
  //     observer.disconnect();
  //   };
  // }, [bodyData]);

  // Build hierarchical structure for the sidebar
  const buildHierarchy = (headings) => {
    const hierarchy = [];
    let currentH1 = null;
    let currentH2 = null;
    let currentH3 = null;

    headings.forEach((heading) => {
      const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000000)}`;
      // console.log(uniqueId); // e.g., "1635112345678-234789"
      const { tagName, innerText, id } = heading;
      heading.id =
        id || `${tagName}-${innerText.replace(/\s+/g, uniqueId).toLowerCase()}`;
      // heading.id =  id || `${tagName}-${innerText.replace(/\s+/g, "-").toLowerCase()}`;

      if (tagName === "H1") {
        currentH1 = { tagName, text: innerText, id: heading.id, children: [] };
        hierarchy.push(currentH1);
        currentH2 = null;
        currentH3 = null;
      } else if (tagName === "H2" && currentH1) {
        currentH2 = { tagName, text: innerText, id: heading.id, children: [] };
        currentH1.children.push(currentH2);
        currentH3 = null;
      } else if (tagName === "H3" && currentH2) {
        currentH3 = { tagName, text: innerText, id: heading.id, children: [] };
        currentH2.children.push(currentH3);
      } else if (tagName === "H4" && currentH3) {
        currentH3.children.push({ tagName, text: innerText, id: heading.id });
      }
    });
    return hierarchy;
  };

  // Scroll to a specific heading when clicking a sidebar item
  // const scrollToHeading = (id) => {
  //   const heading = containerRef.current.querySelector(`#${id}`);
  //   if (heading) {
  //     heading.scrollIntoView({ behavior: "smooth", block: "start" });
  //     setActiveHeadingId(id);
  //   }
  // };

  // // Function to scroll to a heading when clicked in the sidebar
  const scrollToHeading = (id) => {
    const target = document.getElementById(id);
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveHeadingId(id);
    }
  };

  // Toggle the expanded state of a heading
  const toggleHeading = (id) => {
    setExpandedHeadings((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // RIGHT PAGE SET-UP ========KPK-END

  // if (allPosts.length <= 0) {
  //   const goingLoginFunc = () => {
  //     setResToken("");
  //     setCodeNavigatorToken("");
  //     localStorage.removeItem("access_token")
  //     localStorage.removeItem("cn_access_token")
  //     navigate("/");
  //   };
  //   return (
  //     <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
  //       {/* <div className={`text-lg`}>404</div> */}
  //       <div className="{`text-lg`}">Session has been expired.</div>
  //       <button
  //         type="submit"
  //         className={`px-11 py-2 rounded-full bg-lighGray text-Black flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-White active:bg-opacity-60`}
  //         onClick={(e) => goingLoginFunc(e)}
  //       >
  //         Go to Login Page
  //       </button>
  //     </div>
  //   );
  // }

    //SETUP 2-hours SESSION EXPIRATION
  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("cn_access_token");
      setResToken("");
      setCodeNavigatorToken("");
      navigate("/");
    }, 7200000);

    return () => clearTimeout(timer);
  }, [tokenMode === "codenavigator"]);

  // LEFT SIDE-BAR SCROLL WITH POST
  useEffect(() => {
    if (bodyData) {
      sidebarRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isSearchOpen]);
  // }, [bodyData]);

  // LEFT SIDE-BAR SCROLL WITH POST
  const linkMounted = useRef(false);
  useEffect(() => {
    const bodyTime = setTimeout(() => {
      setInitialLeftScrollTrue(true);
    }, 1200);
    return () => clearTimeout(bodyTime);
  }, [leftScrollFlag]);
  // 1ST SCROLL TEST
  useEffect(() => {
    // if (!linkMounted.current) {
    //   linkMounted.current = true;
    //   return;
    // }

    // console.log("observer-outer");
    if (sidebarRef?.current && bodyData) {
      sidebarRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setInitialLeftScrollTrue(false);
      // console.log("observer-inside");
    }
  }, [initialLeftScrollTrue]);
  // console.log("observer", initialLeftScrollTrue, sidebarRef?.current, headings);
  // useEffect(() => {
  //   // if (!linkMounted.current) {
  //   //   linkMounted.current = true;
  //   //   return;
  //   // }

  //   // console.log("observer-outer");

  //   const lefbarScroll = setTimeout(() => {
  //     if (bodyData && sidebarRef?.current) {
  //       sidebarRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       // console.log("observer-inside");
  //     }
  //     setInitialLeftScrollTrue(false);
  //   }, 1300);

  //   return () => clearTimeout(lefbarScroll); // Cleanup timeout on unmount
  // }, [initialLeftScrollTrue]);
  // console.log("observer", initialLeftScrollTrue);

  // 2ND SCROLL TEST
  // useLayoutEffect(() => {
  //   if (!linkMounted.current) {
  //     linkMounted.current = true;
  //     return;
  //   }

  //   // const lefbarScroll = setTimeout(() => {
  //     if (bodyData && sidebarRef?.current) {
  //       sidebarRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       setInitialLeftScrollTrue(false);
  //     }
  //   // }, 8000);

  //   // return () => clearTimeout(lefbarScroll);  // Cleanup timeout on unmount
  // }, [bodyData && initialLeftScrollTrue]);
  // 3rd SCROLL TEST
  // useEffect(() => {
  //   if (!linkMounted.current) {
  //     linkMounted.current = true;
  //     return;
  //   }

  //   console.log("observer-outer")

  //   const observer = new MutationObserver(() => {
  //     if (sidebarRef.current) {
  //       sidebarRef.current.scrollIntoView({
  //         behavior: "smooth",
  //         block: "center",
  //       });
  //       console.log("observer-inside")
  //       observer.disconnect();
  //     }
  //   });

  //   observer.observe(document.body, { childList: true, subtree: true });

  //   return () => observer.disconnect();
  // }, [bodyData && initialLeftScrollTrue]);
  // console.log("observer", initialLeftScrollTrue)

  // 0TH SCROLL TEST
  // useEffect(() => {
  //   if (!sidebarRef.current) return;

  //   const observer = new MutationObserver((mutationsList) => {
  //     for (let mutation of mutationsList) {
  //       // Check if a `ul` element has been added to the sidebarRef
  //       if (mutation.type === 'childList' && sidebarRef.current.querySelector('ul')) {
  //         sidebarRef.current.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'center',
  //         });
  //         observer.disconnect(); // Stop observing after the scroll
  //         break;
  //       }
  //     }
  //   });

  //   // Observe for childList changes (e.g., when `ul` is added)
  //   observer.observe(sidebarRef.current, { childList: true, subtree: true });

  //   // Clean up observer on component unmount
  //   return () => observer.disconnect();
  // }, []);
  // console.log("observer", initialLeftScrollTrue, sidebarRef?.current, headings)

  // Recursive sidebar rendering based on heading hierarchy
  const SidebarMenu = ({
    headings,
    onHeadingClick,
    onToggleClick,
    activeHeadingId,
    expandedHeadings,
    paddLeft,
    margLeft,
  }) => {
    return (
      <ul
        style={{ paddingLeft: `${paddLeft}`, marginLeft: `${margLeft}` }}
        className={`list-none border-l ${
          darkTheme ? "border-l-BoldGray" : "border-l-[#BCD]"
        }`}
        ref={sidebarRef}
      >
        {headings.map((heading, index) => (
          <li key={index}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                onClick={() => {
                  onHeadingClick(heading.id);
                  onToggleClick(heading.id);
                  // setActiveHeadingId(heading.id);
                }}
                style={{
                  display: "block",
                  width: "100%",
                  padding: "4px 10px",
                  // backgroundColor:
                  //   activeHeadingId === heading.id
                  //     ? "#faafdd"
                  //     : "transparent",
                  border: "none",
                  textAlign: "left",
                  cursor: "pointer",
                  fontStyle:
                    heading.tagName === "H1" ? "Barlow-Medium" : "Barlow",
                }}
                className={`clamped-text rounded-xl line-clamp-3 transition-all ${
                  darkTheme ? "hover:bg-[#637474]" : "hover:bg-[#f6c9e3]"
                } ${
                  activeHeadingId === heading.id
                    ? darkTheme
                      ? "bg-[#637474]"
                      : "bg-[#f6c9e3]"
                    : ""
                }`}
              >
                <div className="line-clamp-2">{heading.text}</div>
                {/* {console.log("idddd", heading.id)}
                  {console.log("activeHeading", activeHeadingId)} */}
              </button>
              {heading.children && (
                <button
                  onClick={() => onToggleClick(heading.id)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    // padding: "5px",
                  }}
                  className={`pr-3`}
                >
                  {/* {expandedHeadings[heading.id] ? "-" : "+"} */}
                  {!expandedHeadings[heading.id]
                    ? heading.children.length > 0 && (
                        <MdKeyboardArrowRight
                          className={`w-5 h-5 transition-all ${
                            darkTheme ? "text-BoldGray" : "text-VLightGray"
                          }`}
                        />
                      )
                    : heading.children.length > 0 && (
                        <MdKeyboardArrowRight
                          className={`w-5 h-5 transition-all rotate-90 ${
                            darkTheme ? "text-BoldGray" : "text-VLightGray"
                          }`}
                        />
                      )}
                </button>
              )}
            </div>
            {heading.children && expandedHeadings[heading.id] && (
              <SidebarMenu
                headings={heading.children}
                onHeadingClick={onHeadingClick}
                onToggleClick={onToggleClick}
                activeHeadingId={activeHeadingId}
                expandedHeadings={expandedHeadings}
                paddLeft={"11px"}
                margLeft={"11px"}
              />
            )}
          </li>
        ))}
      </ul>
    );
  };

  // right side bar collapse functionality
  const [removeGrid, setRemoveGrid] = useState("grid");
  const [leftBarHide, setleftBarHide] = useState("block");
  const [iconContentTitle, setIconContentTitle] = useState(
    "Close table of contents"
  );

  const collapseLeftBarFun = () => {
    setleftBarHide(leftBarHide === "block" ? "hidden" : "block");
    setRemoveGrid(removeGrid === "grid" ? "" : "grid");
    setIconContentTitle(
      iconContentTitle === "Close table of contents"
        ? "Open table of contents"
        : "Close table of contents"
    );
  };

  // FEEDBACK MODAL HANDLER
  const [isFeedbackModal, setIsFeedbackModal] = useState(false);
  const [isFeedbackModalPosi, setIsFeedbackModalPosi] = useState(false);
  const feedbackModalToggle = () => {
    setIsFeedbackModal(!isFeedbackModal);
  };
  const feedbackModalTogglePosi = () => {
    setIsFeedbackModalPosi(!isFeedbackModalPosi);
  };

  // PREV BTN IMPLEMENTATION

  const [isOnCurrentUrl, setIsOnCurrentUrl] = useState(true); // Tracks which URL we are currently on
  const location = useLocation();

  // SCROLL LEFTBAR WHEN PREV BTN CLICKED
  useEffect(() => {
    if (bodyData) {
      sidebarRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setIsOnCurrentUrl(true);
    }
  }, [!isOnCurrentUrl]);
  // }, [bodyData]);

  // Load URLs from localStorage on initial render or location change
  useEffect(() => {
    const currentUrl = location.pathname;

    // Get the previous URL from localStorage
    const storedPreviousUrl = localStorage.getItem("previousUrl");
    const storedCurrentUrl = localStorage.getItem("currentUrl");

    // Store the new previous URL in localStorage if there is a current one
    if (storedCurrentUrl && storedCurrentUrl !== currentUrl) {
      localStorage.setItem("previousUrl", storedCurrentUrl);
    }

    // Update the current URL in localStorage
    localStorage.setItem("currentUrl", currentUrl);
  }, [location]);

  const handlePrevBackBtn = () => {
    const currentUrl = localStorage.getItem("currentUrl");
    const previousUrl = localStorage.getItem("previousUrl");

    if (previousUrl) {
      // Toggle navigation between previous and current URLs
      // if (isOnCurrentUrl) {
      //   navigate(previousUrl);
      // } else {
      //   navigate(currentUrl);
      // }

      const mainUrlTogglePrev = isOnCurrentUrl ? previousUrl : currentUrl;
      // console.log("location=middle=", previousUrl);
      // console.log("isOnCurrentUrl=middle=", isOnCurrentUrl);
      if (mainUrlTogglePrev !== location.pathname) {
        // if (previousUrl !== location.pathname) {
        // if (mainUrlTogglePrev) {

        // const freshSlug = previousUrl.replace(/\//g, "");
        const freshSlug = mainUrlTogglePrev.replace(/\//g, "");
        const updatedData = allPosts.map((item) => {
          return {
            ...item,
            posts: item.posts.map((post) => ({
              ...post,
              name: item.name,
            })),
          };
        });
        const SingleArray = updatedData.flatMap((post) => post.posts);

        const filteredData =
          SingleArray.filter((item) => item.slug === freshSlug) || [];

        if (filteredData.length > 0) {
          subToggleDropdown(
            filteredData[0].title,
            filteredData[0],
            0,
            filteredData[0].name
          );
        }

        // navigate(previousUrl, { replace: true });
        navigate(mainUrlTogglePrev, { replace: true });
        setIsOnCurrentUrl(false);
      }

      // Flip the toggle to switch URLs on the next click
      // setIsOnCurrentUrl(!isOnCurrentUrl);

      // WORKING
      // setIsOnCurrentUrl(true);
    } else {
      // console.log("No previous URL to toggle to.");
    }
  };

  return (
    <div>
      {/* TOP MENU FOR small-devices  */}
      {isSmMenuOpen && (
        <div
          className={`HeightHome block md:hidden absolute z-30 top-14 left-0 right-0 ${
            darkTheme ? "bg-LightBlack" : "bg-White"
          }`}
        >
          <TopMenuHeader
            openMenu={openMenu}
            toggleDropdown={toggleDropdown}
            subToggleDropdown={subToggleDropdown}
            headings={headings}
            // sidebar for displaying dropdown
            SidebarMenu={
              <SidebarMenu
                headings={headings}
                onHeadingClick={scrollToHeading}
                onToggleClick={toggleHeading}
                activeHeadingId={activeHeadingId}
                expandedHeadings={expandedHeadings}
                paddLeft={"12px"}
                margLeft={"12px"}
              />
            }
            // bar hide show functionality
            leftBarHide={leftBarHide}
            collapseLeftBarFun={collapseLeftBarFun}
            iconContentTitle={iconContentTitle}
          />
        </div>
      )}
      {isSearchOpen && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen"
          onClick={() => SearchToggle()}
        >
          <Search
            searchInp={searchInp}
            setSearchInp={setSearchInp}
            subToggleDropdown={subToggleDropdown}
          />
        </div>
      )}

      {isFeedbackModal && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => feedbackModalToggle()}
        >
          {/* <div className={`bg-Green`} onClick={(e) => e.stopPropagation()}>
            <div onClick={() => feedbackModalToggle()}>click</div>
          </div> */}
          <FeedbackModal
            subTitle={"What can we do to improve the content?"}
            feedbackModalToggle={feedbackModalToggle}
            feedback={"0"}
          />
        </div>
      )}
      {isFeedbackModalPosi && (
        <div
          className="absolute z-40 top-0 left-0 right-0 bottom-0 bg-[#c8c8da6b] backdrop-blur h-screen flex items-center justify-center"
          onClick={() => feedbackModalTogglePosi()}
        >
          <FeedbackModal
            subTitle="Great! Let us know what you found helpful."
            feedbackModalToggle={feedbackModalTogglePosi}
            feedback={"1"}
          />
        </div>
      )}

      {/* BREAD CRUMB ======== */}
      {/* <div
        className={`grid grid-cols-1 md:grid-cols-3 shadow-md h-[30px] ${
          darkTheme ? "bg-LightBlack" : "bg-lighGray"
        }`}
      >
        <div className="flex items-center">
          <div
            onClick={() => collapseLeftBarFun()}
            className={`hidden md:block p-0.5 px-4 mx-2 active:bg-gray ${
              darkTheme ? "bg-[#252929]" : "bg-White"
            } rounded-lg`}
            title={iconContentTitle}
          >
            {leftBarHide === "block" ? (
              <IoIosArrowBack className={`w-5 h-5`} />
            ) : (
              <IoIosArrowForward className={`w-5 h-5`} />
            )}
          </div>
        </div>
        <div className="md:col-span-2 flex items-center">
          <div className="line-clamp-1 px-2 md:px-0">
            {breadCrumbCategory} <span>/</span> {breadCrumb}
          </div>
        </div>
      </div> */}

      {/* MAIN PAGE grid WRAPPER =====
      =========================== */}
      <div className={`md:px-0 grid grid-cols-1 md:grid-cols-[auto,1fr,1fr]`}>
        <div
          onClick={() => collapseLeftBarFun()}
          // title={iconContentTitle}
          className={`scrollBar-container cursor-pointer w-10 hidden ${
            leftBarHide === "block" ? "md:hidden" : "md:block"
          }`}
        >
          <div className="flex items-center justify-center py-3">
            {leftBarHide !== "block" && (
              <Tooltip
                title="Open table of contents"
                position="right"
                trigger="mouseenter"
                // theme={darkTheme ? "dark" : "light"}
                size="regular"
                delay={1000}
              >
                <IoIosArrowForward className={`w-5 h-5`} />
              </Tooltip>
            )}
          </div>
        </div>
        <div
          className={`scrollBar-container md:w-[300px] lg:w-[400px] xl:w-[420px] ${leftBarHide}`}
        >
          <LeftHome
            // openMenu={openMenu}
            // selectedHighlight={selectedHighlight}
            toggleDropdown={toggleDropdown}
            subToggleDropdown={subToggleDropdown}
            // page heading
            // elementsContent={elementsContent}
            // highlightElement={highlightElement}
            // activeElement={activeElement}
            headings={headings}
            // sidebar for displaying dropdown
            SidebarMenu={
              <SidebarMenu
                headings={headings}
                onHeadingClick={scrollToHeading}
                onToggleClick={toggleHeading}
                activeHeadingId={activeHeadingId}
                expandedHeadings={expandedHeadings}
                paddLeft={"12px"}
                margLeft={"12px"}
              />
            }
            // bar hide show functionality
            leftBarHide={leftBarHide}
            collapseLeftBarFun={collapseLeftBarFun}
            iconContentTitle={iconContentTitle}
          />
        </div>
        <div
          className={`relative scrollBar-container md:col-span-2 ${
            darkTheme ? "bg-[#637474]" : "bg-White"
          }`}
        >
          <MiddleHome
            loadBodyData={bodyData}
            containerRef={containerRef}
            containerRefWrapper={containerRefWrapper}
            isFeedbackModal={isFeedbackModal}
            setIsFeedbackModal={setIsFeedbackModal}
            feedbackModalToggle={feedbackModalToggle}
            setIsFeedbackModalPosi={setIsFeedbackModalPosi}
            feedbackModalTogglePosi={feedbackModalTogglePosi}
            handlePrevBackBtn={handlePrevBackBtn}
          />
        </div>

        {/* <div className="scrollBar-container">
          <RightHome
            elementsContent={elementsContent}
            highlightElement={highlightElement}
            activeElement={activeElement}
          />
        </div> */}
      </div>

      <div className={``}>
        <div
          className={`flex items-center justify-between h-[40px] bg-Black text-White px-2 md:px-4 gap-1 leading-3`}
        >
          <div>
            <img src={FooterLogo} className={`w-24 md:w-32 pl-1`} alt="logo" />
          </div>
          <div className={`text-[12px] md:text-[14px]`}>
            &copy; 2024 CloudFrame, Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
