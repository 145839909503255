import { useEffect, useRef } from 'react';

/**
 * useOutsideClick - Custom hook to detect clicks outside of a specified element.
 *
 * @param {Function} callback - Function to call when a click outside is detected.
 * @returns {Object} - Ref to be attached to the target element.
 */
const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    /**
     * Handle click events
     */
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };

    // Bind the event listeners
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [callback]);

  return ref;
};

export default useOutsideClick;
