import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
import toast from "react-hot-toast";

export function Callback() {
  const { setCodeNavigatorToken, tokenMode, setTokenMode, setResToken, setGraphData } =
    useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("token");

    if (code) {
      exchangeCodeForToken(code);
    } else {
      console.error("Authorization code not found");
    }
    // console.log("tokennnn", code)
  }, []);

  async function exchangeCodeForToken(code) {
    const codeVerifier = localStorage.getItem("access_token");
    const tokenEndpoint =
      "https://askcfbackend.cloudframe.com/api/codenavigator/decrypt-token";

    const data = {
      token: code,
    };

    // const formBody = Object.keys(data)
    //   .map(
    //     (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
    //   )
    //   .join("&");

    try {
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
        // mode: 'cors' // Ensure 'cors' mode is used
      });

      const tokenData = await response.json();
      // console.log("Token response", tokenData);

      if (tokenData.access_token) {
        // sessionStorage.setItem("access_token", tokenData.access_token);
        // setTokenMode("")
        localStorage.removeItem("access_token");
        localStorage.removeItem("uInfo");
        localStorage.removeItem("msInfo");
        setResToken("");
        setGraphData(null);
        localStorage.setItem("cn_access_token", tokenData.access_token);
        setTokenMode("codenavigator");
        setCodeNavigatorToken(tokenData.access_token);
        // navigate("/protected");
        navigate("/");
        toast.success("Successful");
      } else {
        navigate("/");
        toast.error("Credentials failed !");
        console.error("Token exchange failed");
      }
    } catch (error) {
      console.error("Error exchanging code for token", error);
    }
  }

  return (
    // <div className="flex items-center justify-center h-screen">Loading..</div>
    <div className="flex items-center justify-center h-screen px-4">
      <div class={`loading`}>
        <span class="letter text-cfBlue">C</span>
        <span class="letter text-cfBlue">l</span>
        <span class="letter text-cfBlue">o</span>
        <span class="letter text-cfBlue">u</span>
        <span class="letter text-cfBlue">d</span>
        <span class="letter">F</span>
        <span class="letter">r</span>
        <span class="letter">a</span>
        <span class="letter">m</span>
        <span class="letter">e</span>
      </div>
    </div>
  );
}
