import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../UserContext";
import DOMPurify from "dompurify";
import { motion } from "framer-motion";
import { useReactToPrint } from "react-to-print";
import { MdLocalPrintshop } from "react-icons/md";
// import DOMParser from "dom-parser";
import { HiOutlineHandThumbUp } from "react-icons/hi2";
import { HiOutlineHandThumbDown } from "react-icons/hi2";
import { Tooltip } from "react-tippy";

import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function MiddleHome({
  loadBodyData,
  containerRef,
  containerRefWrapper,
  isFeedbackModal,
  setIsFeedbackModal,
  feedbackModalToggle,
  setIsFeedbackModalPosi,
  feedbackModalTogglePosi,
  handlePrevBackBtn,
}) {
  const {
    darkTheme,
    bodyData,
    breadCrumb,
    breadCrumbCategory,
    resToken,
    selectedHighlight,
    isOpenLink,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const fileIcons = {
    pdf: "./icons/pdf-icon.png",
    txt: "./icons/txt-icon.png",
    xlsx: "./icons/xlsx-icon.png",
    java: "./icons/java-icon.png",
    zip: "./icons/zip-icon.png",
  };
  // Function to inject file icons
  const injectFileIcons = (htmlString) => {
    // Create a parser to parse the HTML string
    const parser = new DOMParser();
    const dom = parser.parseFromString(htmlString, "text/html");

    // Find all <a> tags in the parsed HTML
    const anchorTags = dom.getElementsByTagName("a");

    // Iterate through each <a> tag and check its href attribute
    Array.from(anchorTags).forEach((anchor) => {
      const href = anchor.getAttribute("href");
      anchor.style.cssText = `text-decoration: none;`;
      anchor.style.color = darkTheme ? "#11d9f0" : "#00B3F0";
      if (href) {
        anchor.setAttribute("target", "_blank");
        // console.log('aatttt', anchor)

        // Get the file extension from the href attribute
        const extension = href.split(".").pop();
        if (fileIcons[extension]) {
          // Create an <img> element with the corresponding icon
          const img = document.createElement("img");
          img.setAttribute("src", fileIcons[extension]);
          img.setAttribute("alt", `${extension} icon`);
          img.style.marginLeft = "5px";
          img.style.width = "30px"; // Optional: Set the width of the icon

          // Append the <img> element to the <a> tag
          anchor.appendChild(img);

          anchor.removeAttribute("target");
          anchor.setAttribute("download", "");
          // console.log("ancc", anchor);
        }
      }
      // console.log("anccHref", href);
    });
    // console.log("anccTags", anchorTags);

    // Return the modified HTML as a string
    return dom.body.innerHTML;
  };

  // const sanitizedHTML = DOMPurify.sanitize(bodyData.body);
  // const sanitizedHTML = DOMPurify.sanitize(
  //   injectFileIcons(loadBodyData.body ? loadBodyData.body : bodyData)
  // );
  const sanitizedHTML = DOMPurify.sanitize(
    loadBodyData.body ? loadBodyData.body : bodyData
  );

  // Function to print the content
  const handlePrint = () => {
    const printWindow = window.open("", "", "width=800,height=600"); // Open a new window

    // Get the inner HTML of the content div
    const contentToPrint = containerRef.current.innerHTML;

    // Write the HTML content into the new window and call the print function
    printWindow.document.write(`
        <html>
          <head>
            <title>Print Content</title>
            <style>
              /* You can add styles here that only apply to the printed content */
              body {
                font-family: Arial, sans-serif;
              }
            </style>
          </head>
          <body>
            <div class="print-container prose">
              ${contentToPrint}
            </div>
          </body>
        </html>
      `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div
      id="middle-content"
      ref={containerRefWrapper}
      className={`pb-8 scrollBar HeightMiddle overflow-auto ${
        darkTheme ? "bg-[#637474]" : "bg-White"
      }`}
    >
      {/* BREADCRUMB  */}
      <div
        className={`line-clamp-1 px-4 md:px-6 py-1.5 pt-1 ${
          darkTheme ? "bg-[#4d5c5c]" : "bg-[#ddee]"
        }`}
      >
        {breadCrumbCategory} <span>/</span> {breadCrumb}
      </div>

      {/* FEEDBACK MODAL  */}
      {resToken && (
        <div
          className={`flex items-center justify-end gap-2 mt-1 px-4 md:px-6`}
        >
          <div>Was this topic helpful?</div>
          <div className={`flex items-center gap-6`}>
            <div
              className={`cursor-pointer transition-all rounded-xl p-1 ${
                darkTheme ? "active:bg-DarkGray" : "active:bg-gray"
              }`}
              onClick={() => feedbackModalTogglePosi()}
            >
              <Tooltip
                title="Positive Feedback"
                position="top"
                trigger="mouseenter"
                // theme={darkTheme? "dark": "light"}
                size="regular"
                delay={1000}
              >
                <HiOutlineHandThumbUp className={`w-5 h-5`} />
              </Tooltip>
            </div>
            <div
              className={`cursor-pointer transition-all rounded-xl p-1 ${
                darkTheme ? "active:bg-DarkGray" : "active:bg-gray"
              }`}
              onClick={() => feedbackModalToggle()}
            >
              <Tooltip
                title="Negative Feedback"
                position="top"
                trigger="mouseenter"
                // theme={darkTheme? "dark": "light"}
                size="regular"
                delay={1000}
              >
                <HiOutlineHandThumbDown className={`w-5 h-5`} />
              </Tooltip>
            </div>
          </div>
        </div>
      )}

      <motion.div
        id="middlePage"
        ref={containerRef}
        className={`print-container HeightMiddle prose min-w-full px-4 md:px-6 py-4 ${
          darkTheme ? "prose-moon" : "prose-sun"
        }`}
        key={loadBodyData.body ? loadBodyData.slug : bodyData}
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 1, y: 25 }}
        transition={{ delay: 0.3, type: "easeInOut", duration: 0.7 }}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
      <div className="absolute top-20 right-4 z-20">
        <button
          className={`flex items-center justify-center gap-1 px-4 py-1 text-sm rounded-full hover:bg-opacity-65 shadow ${
            darkTheme
              ? "bg-[#aaaaaaaf] active:bg-[#ddd]"
              : "bg-[#ffddaa9d] active:bg-[#ddf]"
          }`}
          onClick={() => handlePrint()}
        >
          Print
          <MdLocalPrintshop className={`w-5 h-5`} />
        </button>
      </div>

      {!isOpenLink && (
        <div className="absolute right-4 bottom-4 z-20">
          <button
            className={`flex items-center justify-center gap-1 px-4 py-1 text-sm rounded-full hover:bg-opacity-65 shadow ${
              darkTheme
                ? "bg-[#aaaaaaaf] active:bg-[#ddd]"
                : "bg-[#ffddaa9d] active:bg-[#ddf]"
            }`}
            onClick={() => handlePrevBackBtn()}
            // onClick={() => navigate(-1)}
            // onClick={() => navigate(`/prerequisites`, { replace: true })}
          >
            Previous
            <IoIosArrowForward className={`w-5 h-5 rotate-180`} />
          </button>
        </div>
      )}
    </div>
  );
}
