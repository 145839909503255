import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

export default function PageNotFound() {
  const {darkTheme} = useContext(UserContext)
  const navigate = useNavigate();
  const goingLoginFunc = () => {
    navigate("/");
  };
  return (
    <div className="flex items-center justify-center flex-col h-screen px-3 gap-y-4">
      {/* <div className={`text-lg`}>404</div>
      <div>Or may be, session has been expired.</div> */}
      <div className="text-center">
        <div className={`font-bold text-lg md:text-xl py-1`}>
          Your session has expired
        </div>
        <div className={`font-Barlow_medium`}>Please go to the login page to continue.</div>
      </div>
      <button
        type="submit"
        className={`px-11 py-2 rounded-full flex items-center gap-1 flex-wrap transition-all font-medium font-Barlow_medium hover:bg-opacity-70 active:bg-opacity-60 ${darkTheme? "bg-White text-Black ": "bg-Black text-White"}`}
        onClick={(e) => goingLoginFunc(e)}
      >
        Go to Login Page
      </button>
    </div>
  );
}
