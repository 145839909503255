import { createContext, useEffect, useState } from "react";
import GetLocalStorageValue from "./reusable/GetLocalStorageValue";

const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const getSystemTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false;

  // const [darkTheme, setDarkTheme] = useState(false);
  const [darkTheme, setDarkTheme] = useState(()=> getSystemTheme());

  const [isSmMenuOpen, setIsSmMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // const [resToken, setResToken] = useState({});
  // const [resToken, setResToken] = useState({});
  const [resToken, setResToken] = useState("");
  const [userLoginInfo, setUserLoginInfo] = useState({});
  const [codeNavigatorToken, setCodeNavigatorToken] = useState("");
  const [tokenMode, setTokenMode] = useState("");

  const [allPosts, setAllPosts] = useState([]);
  const [bodyData, setBodyData] = useState({});
  const [postId, setPostId] = useState("");
  const [selectedHighlight, setSelectedHighlight] = useState(null);
  const [loginDataLoading, setLoginDataLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(null);
  const [breadCrumb, setBreadCrumb] = useState("")
  const [breadCrumbCategory, setBreadCrumbCategory] = useState("")

  const [urlTagIdStore, setUrlTagIdStore] = useState("")
  const [initialLeftScrollTrue, setInitialLeftScrollTrue] = useState(false)
  const [leftScrollFlag, setLeftScrollFlag] = useState(false);

  // profile toggle 
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const [slug4PrevBtn, setSlug4PrevBtn] = useState("")

  // openSource Link in askCN 
  const [isOpenLink, setIsOpenLink] = useState("")

  // MSAL HANDLER VAR 
  const [graphData, setGraphData] = useState(null);
  const [isMsalLogin, setIsMsalLogin] = useState(false);

  useEffect(() => {
    setLoginDataLoading(true);
    const accessToken = localStorage.getItem("access_token");
    const cnAccessToken = localStorage.getItem("cn_access_token");
    // setResToken(accessToken || "");
    // setCodeNavigatorToken(cnAccessToken || "");

    if (cnAccessToken) {
      setTokenMode("codenavigator");
    } else {
      setTokenMode("personal");
      // NORMAL LOGIN info
      const gettingLocalStorageData = GetLocalStorageValue("uInfo", {});
      setUserLoginInfo(gettingLocalStorageData);

      // MS LOGIN info
      const gettingLocalStorageMSData= GetLocalStorageValue("msInfo", {});
      setGraphData(gettingLocalStorageMSData);
    }

    if (!resToken || !codeNavigatorToken) {
      setLoginDataLoading(false);
    } else {
      setLoginDataLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        darkTheme,
        setDarkTheme,
        isSmMenuOpen,
        setIsSmMenuOpen,
        isSearchOpen,
        setIsSearchOpen,
        resToken,
        setResToken,
        allPosts,
        setAllPosts,
        bodyData,
        setBodyData,
        selectedHighlight,
        setSelectedHighlight,
        loginDataLoading,
        setLoginDataLoading,
        codeNavigatorToken,
        setCodeNavigatorToken,
        tokenMode,
        setTokenMode,
        openMenu, setOpenMenu,
        breadCrumb, setBreadCrumb,
        breadCrumbCategory, setBreadCrumbCategory,
        urlTagIdStore, setUrlTagIdStore,
        initialLeftScrollTrue, setInitialLeftScrollTrue,
        leftScrollFlag, setLeftScrollFlag,
        userLoginInfo, setUserLoginInfo,
        postId, setPostId,
        isProfileOpen, setIsProfileOpen,

        isOpenLink, setIsOpenLink,

        graphData, setGraphData,
        isMsalLogin, setIsMsalLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
