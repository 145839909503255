import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { UserContext } from "../../components/UserContext";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { darkTheme, setResToken, setCodeNavigatorToken, setGraphData } =
    useContext(UserContext);
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });

      // MY TRICK
      localStorage.removeItem("access_token");
      localStorage.removeItem("cn_access_token");
      localStorage.removeItem("uInfo");
      localStorage.removeItem("msInfo");
      setResToken("");
      setCodeNavigatorToken("");
      setGraphData(null);
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });

      // MY TRICK
      localStorage.removeItem("access_token");
      localStorage.removeItem("cn_access_token");
      localStorage.removeItem("uInfo");
      localStorage.removeItem("msInfo");
      setResToken("");
      setCodeNavigatorToken("");
      setGraphData(null);
    }
  };

  return (
    <ul>
      <li
        className={`cursor-pointer transition-all px-4 py-1 rounded-xl my-1 ${
          darkTheme
            ? "hover:bg-opacity-70 bg-blue-600 active:bg-opacity-25"
            : "hover:bg-opacity-70 active:bg-opacity-25 bg-lighGray"
        }`}
        onClick={() => handleLogout("popup")}
      >
        {/* Sign out using Popup */}
        Sign out
      </li>
      {/* <li
        className={`cursor-pointer transition-all px-4 py-1 rounded-xl my-1 ${
          darkTheme
            ? "hover:bg-opacity-70 bg-blue-600 active:bg-opacity-25"
            : "hover:bg-opacity-70 active:bg-opacity-25 bg-lighGray"
        }`}
        onClick={() => handleLogout("redirect")}
      >
        Sign out using Redirect
      </li> */}
    </ul>
  );
};
