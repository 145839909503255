import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { useContext } from "react";
import { SignOutButton } from "../../../msal-login/msal-login-buttons/SignOutButton";

export default function MSLogoutModal() {
  const { darkTheme, userLoginInfo, postId } = useContext(UserContext);

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      className={`relative mx-4 w-full md:w-[600px] rounded-xl p-2 md:p-4 shadow ${
        darkTheme ? "bg-DarkGray" : "bg-Bg"
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={`flex items-center justify-center flex-col gap-4`}>
        <div className={`font-Barlow_medium text-lg`}>Are you sure?</div>
        <SignOutButton />
      </div>
    </motion.div>
  );
}
