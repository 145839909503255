import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { SampleDataTesting } from "../../SampleDataTesting";
import { AnimatePresence, motion } from "framer-motion";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Tooltip } from "react-tippy";

export default function LeftHome({
  // openMenu,
  toggleDropdown,
  subToggleDropdown,
  open,
  // selectedHighlight,

  // page heading
  // elementsContent,
  // highlightElement,
  // activeElement,
  headings,
  // dropDown create
  SidebarMenu,

  leftBarHide,
  collapseLeftBarFun,
  iconContentTitle,
}) {
  const { darkTheme, allPosts, openMenu, setOpenMenu, selectedHighlight } =
    useContext(UserContext);

  //   ANIMATION
  const dropdownVariants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        // ease: "easeInOut",
        ease: [0.42, 0, 0.58, 1],
      },
    },
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.5,
        // ease: "easeInOut",
        ease: [0.42, 0, 0.58, 1],
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    closed: {
      opacity: 0,
      y: -20,
    },
    open: { opacity: 1, y: 0 },
  };

  const GetInitialDropdownFunc = async () => {
    const mainArr = await allPosts.flatMap((menu) => menu.posts);
    const findId = await mainArr.filter(
      (post) => post.slug === selectedHighlight
    );
    await setOpenMenu(findId[0]?.id);
  };
  useEffect(() => {
    GetInitialDropdownFunc();
  }, []);
  return (
    <div
      className={`pb-8 scrollBar HeightMiddle border-r ${
        open ? "block p-2 mx-4 border-r-0" : "hidden"
      } md:block overflow-auto
       ${darkTheme ? "border-r-0" : "border-r-gray"}
      `}
      // ${
      //   darkTheme ? "bg-LightBlack" : "bg-Border"
      // }
    >
      {/* <div className={``}> */}
      <div
        onClick={() => collapseLeftBarFun()}
        className={`hidden md:block py-3 pr-2 self-end sticky top-0 z-30 cursor-pointer ${
          darkTheme ? "bg-[#212121]" : "bg-[#eeeeee]"
        }`}
        // title={iconContentTitle}
      >
        <div className={`flex items-center justify-end`}>
          {leftBarHide === "block" && (
            <Tooltip
              title="Close table of contents"
              position="top"
              trigger="mouseenter"
              // theme={darkTheme ? "dark" : "light"}
              size="regular"
              delay={1000}
            >
              <IoIosArrowBack className={`w-5 h-5`} />
            </Tooltip>
          )}
        </div>
      </div>
      {/* </div> */}
      {/* DYNAMIC DROPDOWN MENU  */}
      <div className={`pr-2`}>
        {allPosts.map((menu, index) => (
          <div key={index} className="">
            <div
              // onClick={() => toggleDropdown(index, menu)}
              className={`line-clamp-2 py-1 px-3 rounded-xl w-full text-left font-Barlow_medium flex items-center justify-between`}
            >
              <div className="line-clamp-3">{menu.name}</div>
              {/* {
                menu?.posts?.length > 0 && (
                  <MdKeyboardArrowRight
                    className={`w-5 h-5 ${
                      openMenu === index &&
                      menu?.posts?.length > 0 &&
                      "rotate-90 transition-all duration-500"
                    }`}
                  />
                )
              } */}
            </div>
            <AnimatePresence>
              {/* {openMenu === index && menu.posts.length > 0 && ( */}
              {menu.posts.length > 0 && (
                <motion.ul
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={dropdownVariants}
                  className={`pageHeadingOnRight pl-3 ml-3 border-l ${
                    darkTheme ? "border-l-BoldGray" : "border-l-[#BCD]"
                  }`}
                >
                  {menu.posts.map((item, itemIndex) => (
                    <motion.li
                      variants={itemVariants}
                      key={itemIndex}
                      // className={`font-Barlow_medium active:bg-opacity-15 transition-all ${
                      //   darkTheme
                      //     ? "text-[#bebebe] hover:bg-[#637474]"
                      //     : "text-[#6e6e6e] hover:bg-Violet hover:bg-opacity-30"
                      // } line-clamp-1 py-1 px-3 rounded-xl cursor-pointer hover:text-White ${
                      //   selectedHighlight === item.slug
                      //     ? `${
                      //         darkTheme
                      //           ? "bg-[#637474] bg-opacity-50"
                      //           : "bg-Violet bg-opacity-10"
                      //       }`
                      //     : ""
                      // }`}

                      // onClick={() =>
                      //   subToggleDropdown(item.title, item, itemIndex)
                      // }
                    >
                      {/* <div
                        // className={`flex items-center justify-between`}
                        className={`flex items-center justify-between font-Barlow_medium active:bg-opacity-15 transition-all ${
                          darkTheme
                            ? "text-[#bebebe] hover:bg-[#637474]"
                            : "text-[#6e6e6e] hover:bg-Violet hover:bg-opacity-30"
                        } line-clamp-1 py-1 px-3 rounded-xl cursor-pointer hover:text-White ${
                          selectedHighlight === item.slug
                            ? `${
                                darkTheme
                                  ? "bg-[#637474] bg-opacity-50"
                                  : "bg-Violet bg-opacity-10"
                              }`
                            : ""
                        }`}
                      > */}
                      <div className={`line-clamp-2`}>
                        <div
                          className={`flex items-center justify-between font-Barlow_medium active:bg-opacity-15 transition-all py-1 px-3 rounded-xl cursor-pointer ${
                            darkTheme
                              ? "hover:bg-[#637474] hover:bg-opacity-55"
                              : "hover:bg-Violet hover:bg-opacity-10"
                          } ${
                            selectedHighlight === item.slug
                              ? `${
                                  darkTheme
                                    ? "bg-[#637474] bg-opacity-55"
                                    : "bg-Violet bg-opacity-10"
                                }`
                              : ""
                          }`}
                          onClick={() =>
                            subToggleDropdown(
                              item.title,
                              item,
                              itemIndex,
                              menu.name
                            )
                          }
                        >
                          <div className="line-clamp-2">{item.title}</div>
                          {/* {menu?.posts?.length > 0 && (
                            <MdKeyboardArrowRight
                              className={`w-5 h-5 ${
                                openMenu === item.id &&
                                menu?.posts?.length > 0 &&
                                "rotate-90 transition-all duration-500"
                              }`}
                            />
                          )} */}
                          <div>
                            {menu?.posts?.length > 0 && item.count.h1 !== 0 && (
                              <MdKeyboardArrowRight
                                className={`w-5 h-5 ${
                                  openMenu === item.id &&
                                  headings.length > 0 &&
                                  menu?.posts?.length > 0 &&
                                  "rotate-90 transition-all duration-500"
                                }`}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {openMenu === item.id && SidebarMenu}

                      {/* {menu?.posts?.length > 0 && (
                          <MdKeyboardArrowRight
                            className={`w-5 h-5 ${
                              openMenu === itemIndex &&
                              menu?.posts?.length > 0 &&
                              "rotate-90 transition-all duration-500"
                            }`}
                          />
                        )}
                      </div>
                      {openMenu === itemIndex &&
                        elementsContent?.length > 0 && (
                          <motion.ul
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={dropdownVariants}
                            className={`pl-2 ml-3 border-l ${
                              darkTheme
                                ? "border-l-BoldGray"
                                : "border-l-[#BCD]"
                            }`}
                          >
                            {elementsContent.map((tag, tagIndex) => (
                              <li
                                key={tagIndex}
                                onClick={() => {
                                  // Scroll to the tag when clicked in the sidebar
                                  const target = document.getElementById(
                                    `tag-${tagIndex}`
                                  );
                                  target.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start",
                                    inline: "start",
                                  });
                                  highlightElement(target); // Highlight the corresponding tag
                                }}
                                className={`py-1 px-2 cursor-pointer line-clamp-1 ${
                                  activeElement === `tag-${tagIndex}`
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {tag.textContent}
                                {console.log("page-tags==>", tag)}
                              </li>
                            ))}
                          </motion.ul>
                        )} */}
                    </motion.li>
                  ))}
                </motion.ul>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
}
