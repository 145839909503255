import React, { useContext } from 'react'
import { UserContext } from '../UserContext';

export default function Input(props) {
    const {type, name, onChange, value, className, placeholder, label} = props;
    const {darkTheme} = useContext(UserContext)
  return (
    <div>
      <label className='block'>{label}</label>
      <input {...props} type={type} name={name} onChange={onChange} value={value} className={`py-1 px-3 rounded-xl outline-none ${darkTheme ? "bg-DarkGray" : "bg-White"} ${className}`} placeholder={placeholder} />
    </div>
  )
}
