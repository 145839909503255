import React, { useContext } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../../UserContext";
import { BaseURL } from "../../BaseURL";
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function FeedbackModal({
  subTitle,
  feedbackModalToggle,
  feedback,
}) {
  const { darkTheme, userLoginInfo, postId } = useContext(UserContext);
  const access_token = localStorage.getItem("access_token");
  const [comment, setComment] = useState("");

  const feedbackFunc = async (e) => {
    e.preventDefault();

    if (!comment) {
      toast.error("Enter comment");
      return;
    }
    const formData = {
      user_id: userLoginInfo.user_id,
      post_id: postId,
      feedback: feedback,
      comment: comment,
    };
    const res = await fetch(`${BaseURL}/codenavigator/posts/feedback`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const data = await res.json();
    if (data?.status) {
      feedbackModalToggle();
      setComment("");
      toast.success(
        `${userLoginInfo.user_name} Your feedback has been successfully sent`
      );
    } else {
      toast.error("Something Else");
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      className={`relative mx-4 w-full md:w-[600px] rounded-xl p-2 md:p-4 shadow ${
        darkTheme ? "bg-DarkGray" : "bg-Bg"
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <form>
        <div>
          <div className={`text-lg md:text-lg lg:text-xl pb-2`}>
            Rate this content
          </div>
          <div className={`py-2`}>{subTitle}</div>

          <div className={`flex items-start gap-1 flex-col`}>
            <label className={`text-sm`}>Comment 0/750</label>
            <textarea
              className={`py-1 px-2 md:px-3 rounded-xl w-full ${
                darkTheme
                  ? "bg-LightBlack outline-Blue4"
                  : "bg-Border outline-Orange"
              }`}
              rows="3"
              maxLength={"750"}
              placeholder="Maximum of 750 characters"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <div className={`text-sm text-justify mt-4`}>
            Note: This feedback goes to your product's documentation team and
            does not include a response. Issues that require a response should
            go through CloudFrame support.
          </div>

          <div className={`flex items-start gap-4 mt-4`}>
            <button
              className={`py-1 px-4 rounded-xl transition-all ${
                darkTheme
                  ? "bg-Icon active:bg-gray hover:bg-LightBlack"
                  : "bg-Border active:bg-White hover:bg-Hover"
              }`}
              onClick={() => feedbackModalToggle()}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`py-1 px-4 rounded-xl transition-all ${
                darkTheme
                  ? comment.length >= 3
                    ? "bg-Blue4"
                    : "bg-Icon active:bg-gray hover:bg-LightBlack"
                  : comment.length >= 3
                  ? "bg-Orange"
                  : "bg-Border active:bg-White hover:bg-Hover"
              }
              }`}
              onClick={(e) => feedbackFunc(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </motion.div>
  );
}
