import React, { useContext, useState } from "react";
import { UserContext } from "../../UserContext";
import Input from "../../reusable/Input";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { SampleDataTesting } from "../../SampleDataTesting";
import { motion } from "framer-motion";

export default function Search({ searchInp, setSearchInp, subToggleDropdown }) {
  const { darkTheme, allPosts } = useContext(UserContext);
  // const [loading, setLoading] = useState(false);

  // Search DATA
  const updatedData = allPosts.map((item) => {
    return {
      ...item,
      posts: item.posts.map((post) => ({
        ...post,
        name: item.name,
      })),
    };
  });
  const SingleArray = updatedData.flatMap((post) => post.posts);

  // 4th Search
  const [filteredData, setFilteredData] = useState([]);
 
  // Function to highlight matched text
  const highlightText = (text, searchText) => {
    if (!searchText) return text;
    const regex = new RegExp(`(${searchText})`, "gi");
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === searchText.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: darkTheme? "teal": "yellow" }}>{part}</span>
      ) : part
    );
  };

  // Function to extract plain text from HTML content using DOMParser
  const extractPlainText = (htmlString) => {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(htmlString, "text/html");
    // return doc.body.textContent || "";

    // return htmlString.replace(/<[^>]*>/g, ''); // Regular expression to match HTML tags

    // return htmlString.replace(/<\/?[^>]+(>|$)/g, " "); // Regular expression to match HTML tags

    // Remove HTML tags
    let text = htmlString.replace(/<[^>]*>/g, ''); 
    // Replace multiple spaces or newlines with a single space
    text = text.replace(/\s+/g, ' ').trim();
    // console.log("html-Convert-Plain==>",text)
    return text;
  };

  // Event handler for search input
  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchInp(text);

    if (text.trim() === "") {
      setFilteredData([]);
      return;
    }

    const filtered = SingleArray
      .map((item) => {
        const plainTitle = item.title;
        const plainContent = extractPlainText(item.body);
        
        const isTitleMatch = plainTitle.toLowerCase().includes(text.toLowerCase());
        const isContentMatch = plainContent.toLowerCase().includes(text.toLowerCase());

        // If either title or content contains the search term, highlight it
        if (isTitleMatch || isContentMatch) {
          const highlightedTitle = highlightText(plainTitle, text);
          const highlightedContent = highlightText(plainContent, text);

          return {
            id: item.id,
            title: highlightedTitle,
            body: highlightedContent,
            originalContent: item // Include original HTML content
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    setFilteredData(filtered);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 1, y: 25 }}
      transition={{ delay: 0.2, type: "easeInOut", duration: 0.5 }}
      className={`relative mx-4 mt-8 md:mt-16 md:mx-auto md:w-[680px] lg:w-[850px] rounded-xl`}
      onClick={(e) => e.stopPropagation()}
    >
      {/* <Button onClick={() => setIsSearchOpen(false)}>X</Button> */}

      <div className={`sticky top-0`}>
        <div
          className={`flex items-center shadow gap-1 pl-2 md:pl-3 rounded-xl ${
            darkTheme ? "bg-DarkGray" : "bg-White"
          }`}
        >
          <MagnifyingGlassIcon
            className={`w-5 h-5 ${darkTheme ? "text-[#babec5]" : "text-Icon"}`}
          />
          <div className="flex-grow">
            <Input
              type="text"
              placeholder="Search Content"
              className={`w-full !py-2 !pl-0`}
              value={searchInp}
              // onChange={(e) => setSearchInp(e.target.value)}
              onChange={(e) => handleSearchChange(e)}
              autoFocus
            />
          </div>
        </div>
      </div>

      {/* SEARCH SCROLLING / CONTENT  */}
      <div
        className={
          "rounded-xl searchHeight overflow-y-auto overflow-x-auto mt-2 scrollBar " +
          `${searchInp === "" ? "hidden" : "block"} || ${
            darkTheme ? "bg-Icon" : "bg-White"
          }`
        }
      >
        <>
          {/* {!loading ? ( */}
          <>
            {
              // SampleDataTesting.filter((item) => {
              //   eturn name && name.startsWith(searchValue);
              // })const searchValue = searchInp.toLowerCase();
              //   const name =
              //     item.title.toLowerCase() || item.subTitle.toLowerCase();
              filteredData?.length > 0 ? (
                filteredData.map((post, postIndex) => (
                  <div
                    key={postIndex}
                    className={
                      "cursor-pointer " +
                      `${searchInp === "" ? "hidden" : "block"} ||
                      ${darkTheme ? "bg-DarkGray" : "bg-White"}`
                    }
                    // onClick={() => mergeDataArrFun(item)}
                  >
                    <div
                      onClick={() => {
                        subToggleDropdown(
                          post.originalContent.title,
                          post.originalContent,
                          postIndex,
                          post.originalContent.name
                        );
                        setSearchInp("");
                      }}
                      className={`transition-all flex gap-2 flex-grow flex-wrap border-b px-3 py-3 md:px-4 ${
                        darkTheme
                          ? "border-b-[#444444] hover:bg-[#444444] active:bg-gray"
                          : "border-b-Violet border-opacity-5 hover:bg-opacity-5 hover:bg-Violet active:bg-Violet active:bg-opacity-15"
                      }`}
                    >
                      {/* Content  */}
                      <div className={`font-bold`}>{post.title}</div>
                      {/* <div className={`text-justify`}>{post.body}</div> */}
                    </div>
                    {/* {console.log("pposts", post.body)} */}
                    <div>{/* if you Edit /delet btn impl. here  */}</div>
                  </div>
                ))
              ) : (
                <span
                  className={
                    "py-2 px-3 " +
                    `${searchInp === "" ? "hidden" : "block"} ||
                  ${
                    darkTheme
                      ? "bg-DarkGray text-Orange"
                      : "bg-White text-Violet"
                  }`
                  }
                >
                  Not Matched
                </span>
              )
            }
          </>
          {/* ) : (
             <span className="px-3">Loading..</span>
           )} */}
        </>
      </div>
    </motion.div>
  );
}
